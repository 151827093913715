import {createAction, createSlice} from '@reduxjs/toolkit';

interface IAuthGenerics {
  sessionToken: string;
  isLoggedIn: boolean;
  email: string;
  mobile: string;
  emailLogin: any;
  mobileLogin: any;
  mobileCode: string;
  tempToken: '';
  binList: any[];
  isSelectBRNPage: boolean;
  isShowOtpPage: boolean;
  isShowLogoutPage: boolean;
  isMobileLogin: boolean;
  seedflexMId: string;
  businessName: string;
  currency: string;
  notFound: boolean;
  isIdleTimeout: boolean;
  idleTime: number;
  stepToRedirect: number;
  seedflexPid: string;
  platformName: string;
  platformUrl: string;
  step: number;
}

export const loginReducerLogout = createAction('loginReducerLogout');

const initialState: IAuthGenerics = {
  sessionToken: '',
  tempToken: '',
  isLoggedIn: false,
  binList: [],
  emailLogin: {},
  mobileLogin: {},
  mobileCode: '',
  email: '',
  mobile: '',
  isSelectBRNPage: false,
  isShowOtpPage: false,
  isShowLogoutPage: false,
  isMobileLogin: true,
  seedflexMId: '',
  businessName: '',
  currency: '',
  notFound: false,
  isIdleTimeout: false,
  idleTime: 300000,
  stepToRedirect: 0,
  seedflexPid: '',
  platformName: '',
  platformUrl: '',
  step: 1,
};

const login = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.sessionToken = '';
      state.isLoggedIn = false;
      state.seedflexPid = '';
    },
    setBinList(state, actions) {
      state.binList = actions.payload;
    },
    setTempToken(state, actions) {
      state.tempToken = actions.payload;
    },
    setSessionToken(state, actions) {
      state.sessionToken = actions.payload;
    },
    setSeedflexMId(state, actions) {
      state.seedflexMId = actions.payload;
    },
    setIsMobileLogin(state, actions) {
      state.isMobileLogin = actions.payload;
    },
    setEmailLogin(state, actions) {
      state.emailLogin = actions.payload;
    },
    setMobileLogin(state, actions) {
      state.mobileLogin = actions.payload;
    },
    setIsSelectBRN(state, actions) {
      state.isSelectBRNPage = actions.payload;
    },
    setIsShowOtp(state, actions) {
      state.isShowOtpPage = actions.payload;
    },
    setIsLogout(state, actions) {
      state.isShowLogoutPage = actions.payload;
    },
    setUserEmailWhileLogin(state, action) {
      state.email = action.payload;
    },
    setMobileCode(state, action) {
      state.mobileCode = action.payload;
    },
    setBusinessName(state, action) {
      state.businessName = action.payload;
    },
    setCurrency(state, actions) {
      state.currency = actions.payload;
    },
    setNotFoundData(state, action) {
      state.notFound = action.payload;
    },
    setIdleTimeout(state, action) {
      state.isIdleTimeout = action.payload;
    },
    setIdleTime(state, action) {
      state.idleTime = action.payload;
    },
    setStepToRedirect(state, action) {
      state.stepToRedirect = action.payload;
    },
    setSeedflexPid(state, action) {
      state.seedflexPid = action.payload;
    },
    setPlatformName(state, action) {
      state.platformName = action.payload;
    },
    setPlatformUrl(state, action) {
      state.platformUrl = action.payload;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginReducerLogout, () => initialState);
  },
});

const loginReducer = login.reducer;

export const loginActions = login.actions;

export default loginReducer;
